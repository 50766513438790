import axios from 'axios'
import Cookies from 'js-cookie'
import { createToastInterface } from 'vue-toastification'

const toast = createToastInterface({
  position: 'top-right',
  timeout: 3000
})

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

api.interceptors.request.use(
  config => {
    const { jwtToken } = JSON.parse(Cookies.get('user') || '{}')

    if (jwtToken) {
      config.headers.Authorization = jwtToken
    }
    return config
  }
)

api.interceptors.response.use(response => {
  return response
}, async error => {
  if (error.response.status === 401) {
    toast.error(error.response.data.message ?? 'Não foi possível validar a sua sessão. Faça login novamente.')
  }
  return Promise.reject(error)
})

export default api
