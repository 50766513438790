exports.championshipsState = {
  selectedChampionship: null,
  championships: {
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItens: 0,
    pageSize: 10
  }
}

exports.championshipsMutations = {
  SET_SELECTED_CHAMPIONSHIP (state, payload) {
    state.selectedChampionship = payload
  },
  SET_CHAMPIONSHIPS (state, payload) {
    state.championships.data = payload.data
    state.championships.currentPage = +payload.currentPage
    state.championships.totalPages = +payload.totalPages
    state.championships.totalItens = +payload.totalItens
  }
}
