exports.usersState = {
  user: {},
  users: {
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItens: 0
  }
}

exports.usersMutations = {
  SET_USER (state, payload) {
    state.user = payload
  },
  SET_USERS (state, payload) {
    state.users.data = payload.data
    state.users.totalPages = +payload.totalPages
    state.users.totalItens = +payload.totalItens
  },
  LOGGOUT (state) {
    state.user = {}
  }
}

exports.usersGetters = {
  access_token: state => {
    return state.user.access_token
  }
}
