const achievements = {
  path: '/conquistas',
  title: 'Conquistas',
  component: () => import('@/views/achievements/AchievementsList.vue'),
  meta: {
    navItem: true,
    showNavs: true,
    requiresAuth: true,
    icon: 'mdi-trophy-award'
  }
}

export default achievements
