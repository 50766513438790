import Amplify, { Hub } from 'aws-amplify'
import { createToastInterface } from 'vue-toastification'

Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn_failure':
      var message = decodeURIComponent(data.payload.data.message).replaceAll('+', ' ')
      message = message.replace('PreSignUp failed with error ', '')

      if (
        data.payload.data.code &&
        data.payload.data.code === 'NotAuthorizedException'
      ) {
        message = 'Usuário ou senha incorretos'
      }
      // eslint-disable-next-line no-case-declarations
      const toast = createToastInterface({
        position: 'top-right',
        timeout: 3000
      })
      toast.error(message)
      break

    default:
      break
  }
})

Amplify.configure({
  region: 'us-east-1',
  userPoolId: 'us-east-1_2kLHAPD7z',
  userPoolWebClientId: '3t2u269tka54asd00r8e74apfd',
  identityPoolId: 'us-east-1:684f67ab-7b24-4d38-b848-2fd153060785',
  oauth: {
    domain: 'jangadeiro-sso.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: 'https://sso.jangadeiro.com.br/perfil',
    redirectSignOut: 'https://sso.jangadeiro.com.br',
    responseType: 'token'
  }
})
