exports.navigationState = {
  sidebarColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  drawer: false
}

exports.navigationMutations = {
  SET_DRAWER (state, payload) {
    state.drawer = payload
  }
}
