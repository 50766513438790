const rounds = {
  path: '/rodadas/editar/:id',
  title: 'Rodadas',
  meta: {
    showNavs: true,
    requiresAuth: true
  },
  component: () => import('@/views/rounds/components/Round.vue')
}

export default rounds
