const app = [
  {
    path: '/app/shield-customizer',
    title: 'app',
    meta: {
      navItem: false,
      showNavs: false,
      requiresAuth: false
    },
    component: () => import('@/views/app/ShieldCustomizer.vue')
  },
  {
    path: '/app/shield-preview',
    title: 'app',
    meta: {
      navItem: false,
      showNavs: false,
      requiresAuth: false
    },
    component: () => import('@/views/app/ShieldPreview.vue')
  },
  {
    path: '/app/uniform-customizer',
    title: 'app',
    meta: {
      navItem: false,
      showNavs: false,
      requiresAuth: false
    },
    component: () => import('@/views/app/UniformCustomizer.vue')
  },
  {
    path: '/app/uniform-preview',
    title: 'app',
    meta: {
      navItem: false,
      showNavs: false,
      requiresAuth: false
    },
    component: () => import('@/views/app/UniformPreview.vue')
  }
]

export default app
