exports.teamsState = {
  teams: {
    data: [],
    currentPage: 1,
    totalPages: 0,
    totalItens: 0,
    pageSize: 10
  }
}

exports.teamsMutations = {
  SET_TEAMS (state, payload) {
    state.teams.data = payload.data
    state.teams.currentPage = +payload.currentPage
    state.teams.totalPages = +payload.totalPages
    state.teams.totalItens = +payload.totalItens
  }
}
