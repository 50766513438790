import Vue from 'vue'
import Toast, { POSITION } from 'vue-toastification'
import AOS from 'aos'
import { VueMaskDirective } from 'v-mask'
import Cookies from 'js-cookie'
import DatetimePicker from 'vuetify-datetime-picker'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import request from './utils/request'

import './plugins/amplify'
import './utils/helpers'
import 'aos/dist/aos.css'
import 'vue-toastification/dist/index.css'

Vue.config.productionTip = false
Vue.prototype.$http = request
Vue.prototype.$cookies = Cookies
Vue.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 3000
})
Vue.use(DatetimePicker)
Vue.directive('mask', VueMaskDirective)

new Vue({
  router,
  store,
  vuetify,
  created () {
    AOS.init()
  },
  render: h => h(App)
}).$mount('#app')
