import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { Auth } from 'aws-amplify'
import { createToastInterface } from 'vue-toastification'

import auth from './modules/auth'
import dashboard from './modules/dashboard'
// import users from './modules/users'
import rounds from './modules/rounds'
import sponsors from './modules/sponsors'
import shields from './modules/shields'
import uniforms from './modules/uniforms'
import championships from './modules/championships'
import teams from './modules/teams'
import achievements from './modules/achievements'
import app from './modules/app'

Vue.use(VueRouter)

const routes = [
  ...app,
  ...auth,
  ...championships,
  rounds,
  teams,
  sponsors,
  dashboard,
  shields,
  uniforms,
  achievements
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const toast = createToastInterface({
  position: 'top-right',
  timeout: 3000
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const user = JSON.parse(Cookies.get('user') || '{}')
    if (!user.jwtToken) {
      toast.error('Você precisa estar autenticado para acessar esta página.')
      next('/')
      return
    }

    if (user.role?.toUpperCase() !== 'ADMIN') {
      await Auth.signOut()
      toast.error('Você não tem permissão para acessar esta página.')
      next('/')
      return
    }
  }
  next()
})

export default router
