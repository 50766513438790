exports.modalsState = {
  createTeamModal: false,
  updateTeamModal: false,
  deleteTeamModal: false,

  createChampionshipModal: false,
  updateChampionshipModal: false,
  deleteChampionshipModal: false,

  createRoundModal: false,
  updateRoundModal: false,
  deleteRoundModal: false,
  confirmRoundRankingConsolidationModal: false,

  createSponsorModal: false,
  updateSponsorModal: false,
  deleteSponsorModal: false,

  createShieldSymbolModal: false,
  updateShieldSymbolModal: false,
  deleteShieldSymbolModal: false,

  createShieldModal: false,
  updateShieldModal: false,
  deleteShieldModal: false,

  createUniformModal: false,
  updateUniformModal: false,
  deleteUniformModal: false,

  createAchievementModal: false,
  updateAchievementModal: false,
  deleteAchievementModal: false,

  previewEditorResult: false,
  deleteNewsModal: false
}

exports.modalsMutations = {
  SET_MODAL_STATE (state, payload) {
    state[payload.modal] = payload.value
  }
}
