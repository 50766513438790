import Vue from 'vue'
import moment from 'moment'
import { Auth } from 'aws-amplify'
import axios from 'axios'

Vue.mixin({
  methods: {
    truncate (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    sliceArray (itens, field, size) {
      const _itens = itens.length > size ? itens.slice(0, size) : itens
      var arr = []
      for (var item of _itens) {
        item = field ? item[field] : item
        arr.push(item)
      }

      if (itens.length - size > 0) {
        arr.push(`+${itens.length - size}`)
      }
      return arr
    },
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    async getUserProfile (user) {
      if (!user) {
        return null
      }
      const attributes = user.attributes

      const {
        accessToken: { jwtToken }
      } = await Auth.currentSession()

      const profile = {
        id: attributes.sub,
        username: user.username,
        email: attributes.email,
        full_name: attributes.name,
        phone_number: attributes.phone_number
          ? attributes.phone_number.split('+55').join('')
          : '',
        gender: attributes.gender,
        cpf: attributes['custom:cpf'],
        role: attributes['custom:role'],
        photo: attributes.picture,
        jwtToken
      }

      return profile
    },
    validateCPF (cpf) {
      var sum = 0
      var rest
      var strCPF = cpf.replace(/\./g, '').replace(/-/g, '')

      if (
        strCPF.length !== 11 ||
        !Array.from(strCPF).filter((e) => e !== strCPF[0]).length
      ) {
        return false
      }

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      }
      rest = (sum * 10) % 11

      if (rest === 10 || rest === 11) rest = 0
      if (rest !== parseInt(strCPF.substring(9, 10))) return false

      sum = 0
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      }
      rest = (sum * 10) % 11

      if (rest === 10 || rest === 11) rest = 0

      if (rest !== parseInt(strCPF.substring(10, 11))) return false
      return true
    },
    cleanPhoneNumber (phone_number) {
      return `+55${phone_number
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .join('')}`
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    padWithZeros (num) {
      return num < 10 ? `0${num}` : num
    },
    screenSize () {
      return this.$vuetify.breakpoint.name
    },
    getUniqueListBy (arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    randomColor () {
      return `#${Math.floor(Math.random() * 16777215).toString(16)}`
    },
    uploadImage (url, image, successCallback, errorCallback) {
      const type = image.type
      axios.put(url, this.image, {
        headers: {
          'Content-Type': type
        }
      })
        .then(() => successCallback())
        .catch(() => errorCallback())
    },
    transformUniformSVG (svg, elements) {
      for (var index = 0; index < elements.length; index++) {
        const element = elements[index]
        const elementItens = svg.getElementsByClassName(element.class)
        if (elementItens && elementItens.length) {
          var color = this.randomColor()
          for (const element of elementItens) {
            element.setAttribute('style', `fill: ${color}`)
          }
        }
      }
      return svg
    },
    getUserFromCookies () {
      return this.$cookies.get('user')
    }
  }
})
