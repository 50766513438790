import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { navigationState, navigationMutations } from './modules/navigation'
import { modalsState, modalsMutations } from './modules/modals'
import { usersState, usersMutations, usersGetters } from './modules/users'
import { sponsorsState, sponsorsMutations } from './modules/sponsors'
import { championshipsState, championshipsMutations } from './modules/championships'
import { teamsState, teamsMutations } from './modules/teams'
import { roundsState, roundsMutations } from './modules/rounds'
import { achievementsState, achievementsMutations } from './modules/achievements'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage
  })],
  state: {
    ...navigationState,
    ...modalsState,
    ...usersState,
    ...sponsorsState,
    ...championshipsState,
    ...teamsState,
    ...roundsState,
    ...achievementsState
  },
  mutations: {
    ...navigationMutations,
    ...modalsMutations,
    ...usersMutations,
    ...sponsorsMutations,
    ...championshipsMutations,
    ...teamsMutations,
    ...roundsMutations,
    ...achievementsMutations
  },
  getters: {
    ...usersGetters
  }
})
