const teams = {
  path: '/teams',
  title: 'Times',
  meta: {
    to: '/teams',
    navItem: true,
    showNavs: true,
    requiresAuth: true,
    icon: 'mdi-soccer'
  },
  component: () => import('@/views/teams/TeamsList.vue')
}

export default teams
