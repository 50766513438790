const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/recuperar-senha',
    name: 'Recuperar senha',
    component: () => import('@/views/auth/RecoveryPassword.vue')
  }
]

export default routes
