const sponsors = {
  path: '/patrocinadores',
  title: 'Patrocinadores',
  component: () => import('@/views/sponsors/SponsorsList.vue'),
  meta: {
    navItem: true,
    showNavs: true,
    requiresAuth: true,
    icon: 'mdi-handshake'
  }
}

export default sponsors
