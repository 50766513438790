const dashboard = {
  path: '/dashboard',
  redirect: '/campeonatos',
  title: 'Dashboard',
  meta: {
    to: '/dashboard',
    navItem: false,
    requiresAuth: true,
    showNavs: true,
    icon: 'mdi-view-dashboard'
  },
  component: () => import('@/views/dashboard/Dashboard.vue')
}

export default dashboard
