const shields = {
  path: '/escudos',
  title: 'Escudos',
  component: () => import('@/views/View.vue'),
  meta: {
    navItem: true,
    showNavs: true,
    requiresAuth: true,
    icon: 'mdi-shield-star'
  },
  children: [
    {
      title: 'Escudos',
      path: '/escudos',
      meta: {
        navItem: true,
        showNavs: true,
        requiresAuth: true
      },
      component: () => import('@/views/shields/ShieldsList.vue')
    },
    {
      title: 'Símbolos',
      path: '/escudos/simbolos',
      meta: {
        navItem: true,
        showNavs: true,
        requiresAuth: true
      },
      component: () => import('@/views/shield-symbols/SymbolsList.vue')
    }
  ]
}

export default shields
