const championships = [
  {
    path: '/campeonatos',
    title: 'Campeonatos',
    meta: {
      to: '/campeonatos',
      navItem: true,
      showNavs: true,
      requiresAuth: true,
      icon: 'mdi-trophy'
    },
    component: () => import('@/views/championships/ChampionshipsList.vue')
  },
  {
    path: '/campeonatos/editar/:id',
    title: 'Campeonatos',
    meta: {
      showNavs: true,
      requiresAuth: true
    },
    component: () => import('@/views/championships/components/Championship.vue')
  }
]

export default championships
