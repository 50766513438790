const uniforms = {
  path: '/uniformes',
  title: 'Uniformes',
  component: () => import('@/views/uniforms/UniformsList.vue'),
  meta: {
    navItem: true,
    showNavs: true,
    requiresAuth: true,
    icon: 'mdi-tshirt-crew'
  }
}

export default uniforms
