<template>
  <div id="app">
    <v-app>
      <side-bar v-if="$route.meta.showNavs" />

      <app-bar v-if="$route.meta.showNavs" />

      <v-main>
        <keep-alive>
          <router-view
            :key="$route.fullPath"
          />
        </keep-alive>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  components: {
    AppBar: () => import('@/components/structure/AppBar'),
    SideBar: () => import('@/components/structure/SideBar')
  }
}
</script>

<style lang="scss">
  #app {
    font-family: 'Open Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: #F7F7F7;
  }

  .height-100 {
    min-height: 100vh;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    font-size: 18px !important;
    font-weight: 500;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-size: 16px !important;
  }

  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    -webkit-box-shadow: inset 0 -1px 0px -1px rgba(50,50,50,.75), inset 0 -2px 6px -5px rgba(50,50,50,.75) !important;
    box-shadow: inset 0 -1px 0px -1px rgba(50,50,50,.75), inset 0 -2px 6px -5px rgba(50,50,50,.75) !important;
  }

  .v-list-group__items a {
    margin-left: 15px;
  }
  .child-nav-item {
    display: none;
  }
  .child-nav-item::before {
    opacity: 0 !important;
  }

  .mouse-pointer {
    cursor: pointer;
  }

  .slick-slide {
    width: 200px;
  }
  .scaleAnimation {
    animation: scaleAnimation 1s ease forwards;
  }

  .unscaleAnimation {
    animation: unscaleAnimation 1s ease forwards;
  }

  .slideLeftAnimation {
    animation: slideLeft 2s ease infinite;
  }

  .slideRightAnimation {
    animation: slideRight 2s ease infinite;
  }

  @keyframes scaleAnimation {
    0% {
      transform: scale(1.0);
    }
    100% {
      transform: scale(1.4);
    }
  }

  @keyframes unscaleAnimation {
    0% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1.0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(10%);
    }

    50% {
      transform: translateX(-10%);
    }

    100% {
      transform: translateX(10%);
    }
  }

  @keyframes slideRight {
    0% {
      transform: translateX(-10%);
    }

    50% {
      transform: translateX(10%);
    }

    100% {
      transform: translateX(-10%);
    }
  }
</style>
